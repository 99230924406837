@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap');

$font-stack: Roboto, sans-serif;
$primary-color: #333;

body {
  font: 100% $font-stack;
  color: $primary-color;
  margin: 0px; }

form {
  display: flex;
  flex-direction: column;
  align-items: center; }

#browser {
  display: flex; }

#title-link {
  text-decoration: none;
  font-size: 30px;
  color: $primary-color; }

.sign-in-or {
  line-height: 60px;
  align-self: center; }

.ProductInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1; }

#product-container {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-auto-rows: max-content;
  gap: 15px;
  padding: 20px; }

.box {
  display: flex;
  flex-direction: column;
  padding: 5px 5px; }

#view {
  flex-direction: column; }

h5 {
  margin: 0;
  padding-left: 3px;
  padding-bottom: 3px;
  font-weight: normal; }

button {
  background: transparent;
  border-style: hidden;
  border-radius: 5px;
  font-family: "Roboto", serif;
  padding: 8px;

  &:hover {
    cursor: pointer; }

  > * {
    /*ensures that on button click, regardless of nested tags, the event will be the button event */
    pointer-events: none; } }

.b1 {
  background: #000000;
  color: #FFFFFF;
  width: 100px;
  height: 28px; }

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px; }

.contentButton {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.25);
  border-radius: 9px;

  &:hover {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5); }

  &:focus {
    outline: none;
    border: 2px solid #000000;
    border-radius: 9px; } }

.nestedImage {
  max-height: 50%; }

.image-with-info {
  margin-bottom: 10px; }

#view {
  margin-bottom: 20px; }

.error {
  color: #FF0000; }

a {
  color: black;
  text-decoration: none; }

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0; }

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@media only screen and (max-width: 1224px) {
  #container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; }

  #navbar {
    //only way to override navbar width in-line. Inline is because the width changes based on whether the alt panel is open
    width: 100vw;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DDDDDD; }

  #moodboardctrldiv {
    display: flex; }

  #moodboardctrllabel {
    display: none; } }


@media only screen and (min-width: 480px) {
  #container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100vh; }

  #moodboardcontainer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: calc(100vh - 50px); }

  #moodboard, #collection {
    display: inline-block;
    zoom: 1;
    vertical-align: top;
    font-size: 16px; }

  #moodboardctrlpanel, #moodboardctrllabel, #moodboardctrldiv {
    display: inline-flex;
    flex-direction: row;
    align-items: center; }
  #moodboardctrlpanel {
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    height: 40px; }

  #moodboardctrllabel {
    justify-content: flex-start; }
  #moodboardctrldiv {
    text-align: center;
    justify-content: space-between;
    height: 100%;
    width: 100%; }
  #moodboardloading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000; }

  #collection {
    width: 25%; }
  #collectionimglabel, #collectionimg {
    margin-left: 25px;
    width: 80%; }
  #collectionimglabel {
    height: 30px; }
  #collectionimg {
    border-radius: 3%; }

  .packright {
    float: right; }

  .alternativesctrlpanel {
    display: flex;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between; }

  .google-sign-in {
    &:hover {
      cursor: pointer; } }

  .my-swal {
    z-index: 1000000 !important; }

  #forgot-password {
    font-size: 14px;
    align-self: start;
    padding: 8px 0;
    &:hover {
      cursor: pointer; }
    &:focus {
      outline: none; } }

  #mobile-moodboardctrllabel {
    display: none; } }
